html{
    scroll-behavior: smooth;
}
.densed-list
{
    background-color: #efefef;
}
.ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
}

/* Snow Theme */
.ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.ql-editor {
    min-height: 18em;
    max-height: 18em;
    overflow-y: auto;
}
.sticky-drawer
{
    top:60px  !important;
}
.drawer-list
{
    height: 100vh !important;
    top: 70px Impo !important;

}