.saleCard {
  position: relative;
  width: 88%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 12px;
  border-radius: 8px;
  cursor: pointer;
}

.saleCard::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  inset: 0;
  left: -5px;
  margin: auto;
  border-radius: 10px;
  background: linear-gradient(-45deg, #FFEA28 40%, #00dbde 40%);
  z-index: -10;
  pointer-events: none;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.saleCard::after {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
  background: linear-gradient(-45deg, #FFEA28 40%, #00dbde 40%);
  transform: translate3d(0, 0, 0) scale(0.95);
  filter: blur(5px);
}


.saleCard p:last-child {
  color: #e81cff;
  font-weight: 600;
}

.saleCard:hover::after {
  filter: blur(30px);
}

.saleCard:hover::before {
  transform: rotate(-90deg) scaleX(1.34) scaleY(0.77);
}

input#asynchronous-trip {
  color: black;
  opacity: 0.99;
  /* Firefox */
  font-weight: 400;
  font-size: 14px;
}

input#asynchronous-trip::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 0.99;
  /* Firefox */
  font-weight: 500;
  font-size: 14px;
}

.searchTrip svg {
  color: #2f86a6 !important;
}

body {
  overflow-x: hidden;
}

.slick-prev {
  /* background: #e4e4e4; */
  border: 2px solid #2f86a6;
  border-radius: 100px;
  padding: 5px;
  color: #2f86a6;
  font-size: 30px !important;
  left: auto !important;
  right: 60px !important;
  font-weight: 800;
  box-shadow: 0 0 5px #2f86a680;
  bottom: -50px;
  top: auto !important;
  z-index: 999;
}

.slick-next {
  right: 10px !important;
  /* background: #e4e4e4; */
  border: 2px solid #2f86a6;
  border-radius: 100px;
  padding: 5px;
  color: #2f86a6;
  font-size: 30px !important;
  font-weight: 800;
  box-shadow: 0 0 5px #2f86a680;
  bottom: -50px;
  top: auto !important;
  z-index: 999;
}

/* .makehappier .slick-prev {
    left: -15px !important;
  }
.makehappier .slick-next {
    right: -15px !important;
} */
.slick-next:hover,
.slick-prev:hover {
  color: #fff !important;
  background: #2f86a6 !important;
}

.testimonilaBox {
  position: relative;
  display: flex;
  padding: 20px 25px;
  gap: 17px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 17%) 0px 4px 16px;
  border-radius: 20px;
  margin: 5px 6px;
}

.testimonilaBox #imgbox {
  width: 7.61vw;
  height: 7.61vw;
  border: 3px solid #2f86a6;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  position: relative;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.testimonilaBox #img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #2f86a6;
  object-fit: cover;
  border-radius: 50%;
  height: 6.58vw;
  width: 6.58vw;
}

#testimonial_content {
  margin-left: 10px;
}

#testimonial_content .name {
  font-size: 14px;
  color: black;
  font-weight: 500;
  margin: 0;
}

#testimonial_content .subtitle {
  font-size: 12px;
  color: #000000ab;
  font-weight: 400;
  margin: 0;
}

#testimonial_content .desc {
  margin: 0;
  position: relative;
  font-style: normal;
  font-weight: 400;
  color: rgba(12, 12, 12, 0.65);
  font-size: 13.33px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding: 15px 0px 0px 18px;
}

#testimonial_content span {
  position: absolute;
  top: 8px;
  left: 8.63px;
  margin-left: -8.63px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  color: #2f86a6;
  margin-right: 9.37px;
}

#testimonialLink {
  font-weight: 500;
  font-size: 13.33px;
  line-height: 20px;
  position: relative;
  bottom: 0px;
  right: 10px;
  cursor: pointer;
  color: #2f86a6;
  align-self: flex-end;
  float: right;
  text-decoration: none;
}

.video-box {
  width: 100%;
  height: 100%;
  background-size: 100% 100% !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  border-radius: 30px;
}

.yplay {
  fill: hsl(0deg 69.15% 61.2% / 80%);
}

.yplay:hover {
  fill: red;
}

.borderFooter {
  display: block;
}

.callIcon {
  /* border-radius: 50%; */
  bottom: 5vh;
  height: 80px;
  padding: 10px;
  position: fixed;
  right: 20px;
  width: 80px;
  transition: ease-in-out 500ms;
}

.callIcon:hover {
  transform: scale(1.3);
}

@media(max-width:767px) {
  .borderFooter {
    display: none;
  }

  .slick-prev {
    left: 150px !important;
    right: 0 !important;
    bottom: -60px;
  }

  .slick-next {
    right: 150px !important;
    left: auto !important;
    bottom: -60px;
  }

  .testimonilaBox {
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding: 20px 19.63px;
  }

  .testimonilaBox #imgbox {
    height: 104px;
    width: 104px;
  }

  .testimonilaBox #img {
    height: 90px;
    width: 90px;
  }

  .dropdown_fieldset div {
    padding: 3px 5px;
    padding-right: 20px;
  }
}

@media(max-width:450px) {
  .slick-prev {
    left: 130px !important;
    right: 0 !important;
    bottom: -60px;
  }

  .slick-next {
    right: 130px !important;
    left: auto !important;
    bottom: -60px;
  }
}

@media(max-width:370px) {
  .slick-prev {
    left: 110px !important;
    right: 0 !important;
    bottom: -60px;
  }

  .slick-next {
    right: 110px !important;
    left: auto !important;
    bottom: -60px;
  }
}

.HoverLink {
  transform: scale(1.2);
  -moz-transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.styled_heading {
  font-size: 25px;
  position: relative;
  padding: 10px 40px 10px 0px;
}

@media screen and (max-width: 550px) {
  .styled_heading {
    font-size: 20px;
    position: relative;
    padding: 10px 30px 15px 0px;
  }
}

@media screen and (max-width: 360px) {
  .styled_heading {
    font-size: 18px;
    position: relative;
    padding: 10px 25px 15px 0px;
  }
}

.styled_heading:after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 0px;
  height: 50px;
  background-image: url(../../public/plane_underline.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;

}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  /* Optional: add a semi-transparent background */
  z-index: 9999;
  /* Ensure it is above other elements */
}

.menuLink {
  min-width: 150px !important;
}

.menuLink .menuItemsub {
  margin-bottom: 5px;
  font-size: 15px !important;
}

.swipe-search-wrapper {
  width: 100%;
  padding: 15px;
  position: relative;
}

.leadSearch {
  margin-top: 45px;
}

.searchSwipe-closer {
  /* position: absolute !important; */
  /* right: 0 !important; */
  left: auto;
  top: 0;
  z-index: 999;
  padding: 15px;
  margin-bottom: 55px;
}

.MuiDrawer-paper {
  height: 400px !important;
}

/* .slick-list {
  padding: 0 20% 0 0 !important;
} */